<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-brawl-stars-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">خرید ربات: نتیجه پرداخت</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div
        class="
          w-full
          md:w-8/12
          lg:w-6/12
          xl:w-5/12
          mx-auto
          bg-blueGray-50
          border border-coolGray-200
          rounded-md
          overflow-hidden
        "
      >
        <h2
          class="text-lg font-bold text-center p-3 bg-green-500 text-green-50"
          v-if="pay.status >= 100"
        >
          سفارش : {{ pay.id }}
        </h2>
        <h2 class="text-lg font-bold text-center p-3 bg-red-500 text-red-50" v-else>
          سفارش : {{ pay.id }}
        </h2>
        <div>
          <div class="p-2 font-bold text-center odd:bg-blueGray-100">
            {{ pay.description }}
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">مدت</div>
            <div class="font-bold text-left">{{ pay.info_json.month }} ماه</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">نوع اشتراک</div>
            <div class="font-bold text-left">{{ list_type[pay.info_json.type] }}</div>
          </div>
          <div class="p-2 text-center font-bold text-x">
            {{ $number.format(pay.amount) }} تومان
          </div>
          <div
            class="text-lg font-bold text-center p-3 bg-green-500 text-green-50"
            v-if="pay.status >= 100"
          >
            پرداخت موفق
          </div>
          <div class="text-lg font-bold text-center p-3 bg-red-500 text-red-50" v-else>
            پرداخت ناموفق
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="flex w-full">
        <div class="flex-1 mx-1">
          <Button
            :to="{
              name: 'GroupOwner',
              params: { id: $store.state.user.id },
            }"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="sky"
          >
            گروه های من
          </Button>
        </div>
        <div class="flex-1 mx-1">
          <Button
            :to="{ name: 'Panel', params: { id: $store.state.user.id } }"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="teal"
          >
            پنل
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import _ from "lodash";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      sending: false,
      prices: {},
      group: {},
      L_: _,
      list_type: {
        green: "سبز",
        blue: "آبی",
        bronze: "برنزی",
        silver: "نقره‌ای",
        golden: "طلایی",
      },
    };
  },
  mounted() {
    this.getData();
    // this.getPrices();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get(
          "/api/group-buy-bot/" +
            $this.$route.params.groupId +
            "/" +
            $this.$route.params.payId,
          {
            params: {
              id: $this.$store.state.user.id,
              // date: $this.date,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.pay = response.data.pay;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
